const accordionItem = document.querySelectorAll('.accordion-item');

accordionItem.forEach((item, index) => {
    let accordionHeader = item.querySelector('.accordion-header');

    accordionHeader.addEventListener('click', () => {
        item.classList.toggle('active');

        let accordionContent = item.querySelector('.accordion-content');

        if(item.classList.contains('active')) {
            accordionContent.style.height = ` ${accordionContent.scrollHeight}px`;
        } else {
            accordionContent.style.height = "0px";
        };

        removeActive(index);
    })
})

function removeActive(index1) {
    accordionItem.forEach((item2, index2) => {
        if (index1 != index2) {
            item2.classList.remove('active');

            let des = item2.querySelector('.accordion-content');
            des.style.height = "0px";
        };
    });
};