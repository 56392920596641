import "../images/america-chip.png";
import "../images/facebook.png";
import "../images/instagram.png";
import "../images/site-seguro.png";
import "../images/favicon-lp.ico";
import "../images/digicert.png";
import "../images/SELORA1000.png";
import "../images/o-meu-chip.png";
import "../images/chip-americano-cel.png";
import "../images/chip-americano-sms.png";
import "../images/chip-americano-chat.png";
import "../images/chip-americano-travel.png";
import "../images/chip-celular-internacional.png";
import "../images/chip-de-celular-internacional.png";
import "../images/chip-telefone-internacional.png";
import "../images/chip-de-telefone-internacional.png";
import "../images/vale-a-pena-comprar-chip-internacional-no-brasil.png";
import "../images/chip-internacional-brasil.png";
import "../images/chips-internacionais-ray.png";
import "../images/chips-internacionais-premium.png";
import "../images/chips-internacionais-photo.png";
import "../images/chips-internacionais-monitor.png";
import "../images/chips-internacionais-modem.png";
import "../images/chips-internacionais-calc.png";
import "../images/chips-internacionais-umbrella.png";
import "../images/chips-internacionais-cel.png";
import "../images/chip-de-viagem-internacional.png";
import "../images/arrow-bottom.png";
import "../images/cel.png";
import "../images/play.png";
import "../images/o-meu-chip-mobile.png";
import "../images/whatsapp-icon.png";
import "../images/whatsapp-back.png";
import "../images/conect-whats.png";
import "../images/icons-instagram.png";
import "../images/icons-tiktok.png";
import "../images/icons-youtube.png";