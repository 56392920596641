const selectIframe = document.querySelector('.view-trip-watch-video-overlay');

selectIframe.addEventListener("click", function() {
    const playImg = document.querySelector('.play');
    const iframe = document.querySelector('.view-trip-watch-video iframe');
    const player = new Vimeo.Player(iframe);

    playImg.classList.toggle('active');

    if(playImg.classList.contains('active')) {
        player.play();
    } else {
        player.pause();
    }
});
